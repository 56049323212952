h1,
h2,
h3,
h4 {
  font-weight: 300;
}

h2,
h3,
h4 {
  margin-top: 50px;
}

a {
  color: #1e7400;
}
img {
  max-width: 600px;
}

.react-datepicker-wrapper {
    display: flex;     
}

.textarea {
    max-width: 100%;
    min-width: 100%;
}

.formInput[name="journalpostnummer"] {
    border-width: 1px;
 }

 #FormInput {
    height: fit-content !important;
 }

.connector {
  position: absolute;
  border: 1px solid #b1b6ba;
  right: 49px;
}

.connector.middle {
  top: 49px;
}

.connector.top {
  top: 0px;
}
.connector.bottom {
  top: 50px;
}
.connector.horizontal {
  width: 50px;
}
.connector.vertical {
  height: 50px;
}

.connector-container {
  position: relative;
  height: 100px;
  width: 100px;
}

.dot {
  width: 50px;
  height: 50px;
  border: 1px solid #b1b6ba;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
}
.dot.not-started {
  background-color: white;
  color: black;
}
.dot.started {
  background-color: #1d96ca;
  color: white;
}
.dot.finished {
  background-color: #51a026;
  color: white;
}

.content {
  align-self: center;
  flex: 1;
}

.content.left {
  margin-right: 10px;
  text-align: right;
}

.content.right {
  margin-left: 10px;
}

.row {
  display: flex;
  justify-content: center;
}

.row.milestone {
  max-height: 50px;
}

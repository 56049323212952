
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

input::-ms-clear {
  width: 0;
  height: 0;
}

input {
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
}
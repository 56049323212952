.textinput {
    display: flex;
    background-color: white;
    box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2);
}

#textinput-input {
    border: none;
    padding: 5px;
}

#textinput-input:focus {
    outline: none;
}

.textinput-button {
    border: none;
    border-radius: 0px;
    background-color: white;
    padding: 2px;
}
textarea {
  &:focus {
    outline: 1px solid #51A026
  }
}

.formInput {
  &:focus {
    outline: 1px solid #51A026
  }
}
.text-input {
    border-bottom: 2px solid hsl(0,0%,80%);
    display: flex;
    height: 35px;

    &:focus-within {
        border-color: rgba(255, 255, 255, 0);
        transition: border-color 0.2s linear;
    }

    button {
        display: inline;
        visibility: hidden;
        cursor: pointer;
        opacity: 0;
        transition: opacity ease-in-out 0.3s;

        
        &.show {
            visibility: visible;
            opacity: 1;
            transition: opacity ease-in-out 0.3s;

            &:hover {
                opacity: 0.3;
            }
        }
    }

    input {
        border: none;
        width: 100%;
        font-size: 12pt;
        padding-bottom: 8px;
        
        &:focus {
            outline: none;
        }
    }

    &:focus-within ~ .search-border {
        width: 100%;
        border-top: 2px solid #51a026;
        transition: width ease-in-out 0.3s;
    }

    & > input:focus ~ .search-border {
        width: 100%;
        outline: 5px auto -webkit-focus-ring-color;
        border-top: 2px solid #51a026;
        /* rules here, which Edge will apply */
    }

    .search-input-icons {
        width: 50px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 10px;
        display: table-row;

        .search-input-icons > * {
            vertical-align: middle;
        }
    }

}

.search-border {
    opacity: 1;
    width: 0;
    border-top: 2px solid whitesmoke;
    margin-top:-2px;
    z-index: 3;
    position: relative;
}
.nkm-mapbox-map {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;

  #map {
    height: 100%;
    width: 100%;
    max-height: 100%;
  }

  /* TODO: Denne burde egentlig kommet fra @norkart/nkm-mapbox-map  */
  .marker {
    background-image: url("https://res.cloudinary.com/dgbxah2xy/image/upload/v1574343963/e-torg-bilder/marker.png");
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }
  
}

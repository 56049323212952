.drawcontrol-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    margin-top: 10px;
    margin-left: 10px;
}

.drawcontrol-active-mode {
    display: flex;
    line-height: 26px;
    border: none;
    background-color: #f0f0f0;
    margin-top: 10px;
    padding: 5px;
}

.fix-tooltip-on-disabled {
    width: 40px;
    height: 40px;
}
.drawcontrol-buttons {
    display: flex;
}

.drawcontrol-button {
    background-color: white;
    border-radius: 0px;
    border: 1px solid gray;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: 1px solid gray
}

.drawcontrol-button-active {
    background-color: lightgray;
    border-radius: 0px;
    border: 1px solid gray;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.drawcontrol-button:hover:enabled {
    background-color: lightgray;
}

.drawcontrol-container :disabled {
    background-color: rgb(240, 240, 240);
}
.drawcontrol-container :disabled svg {
    opacity: 0.5;
}
html {
  height: 100%;
  font-size: 16px;
}

body {
  min-height: 100%;
  padding: 0 !important;
  overflow-y: scroll;
}

#content {
  padding: 20px;
}

input,
select {
  font-size: 1rem;
  line-height: 1.5px;
  background-color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#plan-search::-ms-clear {
  display: none;
}

#cookie-information-template-wrapper {
  font-family: Arial, Helvetica, sans-serif;
}

#topbar a {
  color: default;
  text-decoration: none;
}

.tabbable-table-link {
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.tabbable-table-link:focus {
  outline: none;
}

.tabbable-table-wrapper tr.active-row {
  background-color: rgba(0, 0, 0, 0.07);
}
